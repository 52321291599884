import React from "react"

const WriteEditList: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.002 4.964a1 1 0 011-1h16a1 1 0 010 2h-16a1 1 0 01-1-1zm0 4a1 1 0 011-1h6.5a1 1 0 010 2h-6.5a1 1 0 01-1-1zm12.014.707a3 3 0 014.242 0l1.037 1.037a3 3 0 010 4.242l-7.721 7.722a1 1 0 01-.707.292H8.002a1 1 0 01-1-1V18.1a1 1 0 01.293-.708l7.72-7.72zm2.828 1.415a1 1 0 00-1.414 0l-7.428 7.428v2.45h2.45l7.429-7.428a1 1 0 000-1.414l-1.037-1.036zM3 12.964a1 1 0 011-1h3.002a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default WriteEditList
