import React from "react"

const Bookguide: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 5.964a3 3 0 013-3h10a3 3 0 013 3v14a3 3 0 01-3 3H7a3 3 0 01-3-3v-14zm2 14a1 1 0 001 1h10a1 1 0 001-1v-1.17c-.313.11-.65.17-1 .17H7a1 1 0 00-1 1zm12-4a1 1 0 01-1 1H7c-.35 0-.687.06-1 .171V5.965a1 1 0 011-1h10a1 1 0 011 1v10zm-10-8a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zm0 4a1 1 0 011-1h3a1 1 0 110 2H9a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Bookguide
