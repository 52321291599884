import React from "react"

const CopyOutline: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3.1875C1.5 2.25552 2.25552 1.5 3.1875 1.5H10.3125C11.2445 1.5 12 2.25552 12 3.1875V6H14.8125C15.7445 6 16.5 6.75552 16.5 7.6875V14.8125C16.5 15.7445 15.7445 16.5 14.8125 16.5H7.6875C6.75552 16.5 6 15.7445 6 14.8125V12H3.1875C2.25552 12 1.5 11.2445 1.5 10.3125V3.1875ZM7.5 14.8125C7.5 14.9161 7.58395 15 7.6875 15H14.8125C14.9161 15 15 14.9161 15 14.8125V7.6875C15 7.58395 14.9161 7.5 14.8125 7.5H7.6875C7.58395 7.5 7.5 7.58395 7.5 7.6875V14.8125ZM10.5 6H7.6875C6.75552 6 6 6.75552 6 7.6875V10.5H3.1875C3.08395 10.5 3 10.4161 3 10.3125V3.1875C3 3.08395 3.08395 3 3.1875 3H10.3125C10.4161 3 10.5 3.08395 10.5 3.1875V6Z"
        fill="currentColor"
        fillOpacity="0.7294"
      />
    </svg>
  )
}

export default CopyOutline
