import React from "react"

const Sparkle2: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.66669C10.368 1.66669 10.6924 1.90808 10.7982 2.26056C11.3976 4.25873 12.1747 5.68371 13.2455 6.75452C14.3163 7.82532 15.7413 8.60238 17.7395 9.20183C18.0919 9.30758 18.3333 9.63201 18.3333 10C18.3333 10.368 18.0919 10.6925 17.7395 10.7982C15.7413 11.3977 14.3163 12.1747 13.2455 13.2455C12.1747 14.3163 11.3976 15.7413 10.7982 17.7395C10.6924 18.092 10.368 18.3334 10 18.3334C9.63199 18.3334 9.30756 18.092 9.20181 17.7395C8.60236 15.7413 7.8253 14.3163 6.75449 13.2455C5.68368 12.1747 4.25871 11.3977 2.26054 10.7982C1.90805 10.6925 1.66666 10.368 1.66666 10C1.66666 9.63201 1.90805 9.30758 2.26054 9.20183C4.25871 8.60238 5.68368 7.82532 6.75449 6.75452C7.8253 5.68371 8.60236 4.25873 9.20181 2.26056C9.30756 1.90808 9.63199 1.66669 10 1.66669ZM4.88948 10C6.07535 10.544 7.08159 11.2156 7.933 12.067C8.78441 12.9184 9.45605 13.9247 10 15.1105C10.5439 13.9247 11.2156 12.9184 12.067 12.067C12.9184 11.2156 13.9246 10.544 15.1105 10C13.9246 9.45607 12.9184 8.78444 12.067 7.93303C11.2156 7.08162 10.5439 6.07537 10 4.8895C9.45605 6.07537 8.78441 7.08162 7.933 7.93303C7.08159 8.78444 6.07535 9.45607 4.88948 10Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Sparkle2
