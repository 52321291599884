import React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { IconType, OtherIcon, OutlineIcon, SolidIcon } from "../icon"
import IconRenderer from "../icon/icon-renderer"
import cn from "../../utils/classname"

interface IconProps {
  type: IconType
  startIconName?: OtherIcon | OutlineIcon | SolidIcon
  endIconName?: OtherIcon | OutlineIcon | SolidIcon
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof btnCva> & {
    icon?: IconProps
    as?: React.ElementType
  }

export const btnCva = cva(
  [
    "flex",
    "items-center",
    "justify-center",
    "transition-all",
    "duration-300",
    "ease-out",
    "box-border",
  ],
  {
    variants: {
      variant: {
        solid: [],
        soft: [],
        ghost: [],
        outline: [],
        alternative: [],
      },
      theme: {
        primary: [],
        accent: [],
        neutral: [],
        danger: [],
      },
      size: {
        1: ["px-2", "rounded", "font-medium", "text-jar-2", "gap-1", "min-h-6"],
        2: [
          "px-3",
          "rounded-md",
          "font-medium",
          "text-jar-3",
          "gap-1",
          "min-h-8",
        ],
        3: [
          "px-3",
          "rounded-lg",
          "font-medium",
          "text-jar-3",
          "gap-1",
          "min-h-10",
        ],
        4: [
          "px-4",
          "rounded-[10px]",
          "font-medium",
          "text-jar-4",
          "gap-2",
          "min-h-12",
        ],
        5: [
          "px-5",
          "rounded-xl",
          "font-medium",
          "text-jar-5",
          "gap-2",
          "min-h-14",
        ],
      },
      iconOnly: {
        true: "p-0 gap-0",
      },
      width: {
        full: "w-full",
        fit: "w-auto",
      },
      rounded: {
        full: "rounded-full",
      },
      loading: {
        true: "loading",
      },
    },
    compoundVariants: [
      {
        variant: "solid",
        theme: "primary",
        className:
          "bg-primary-9 text-white hover:bg-primary-10 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "soft",
        theme: "primary",
        className:
          "bg-primary-alpha-3 text-primary-alpha-11 hover:bg-primary-alpha-4 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "outline",
        theme: "primary",
        className:
          "bg-transparent border border-primary-alpha-6 text-primary-11 hover:bg-primary-alpha-2 hover:border-primary-alpha-2 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "ghost",
        theme: "primary",
        className:
          "bg-transparent text-primary-11 hover:bg-primary-alpha-3 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "alternative",
        theme: "primary",
        className:
          "bg-panel-1 text-primary-11 border border-primary-alpha-6 shadow-[0px_1px_2px_0px_#0000000F_0px_0px_0px_1px_#00000000] hover:bg-panel-3 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },

      {
        variant: "solid",
        theme: "neutral",
        className:
          "bg-neutral-12 text-neutral-1 hover:opacity-[0.82] disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "soft",
        theme: "neutral",
        className:
          "bg-neutral-alpha-3 text-neutral-12 hover:bg-neutral-alpha-4 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "outline",
        theme: "neutral",
        className:
          "bg-transparent border border-neutral-alpha-6 text-neutral-12 hover:bg-neutral-alpha-3 hover:border-neutral-alpha-2 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "ghost",
        theme: "neutral",
        className:
          "bg-transparent text-neutral-12 hover:bg-neutral-alpha-3 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "alternative",
        theme: "neutral",
        className:
          "bg-panel-1 text-neutral-12 border border-neutral-alpha-6 shadow-[0px_1px_2px_0px_#0000000F_0px_0px_0px_1px_#00000000] hover:bg-panel-3 disabled:text-neutral-alpha-8 disabled:bg-panel-3",
      },
      {
        variant: "solid",
        theme: "accent",
        className:
          "bg-accent-9 text-white hover:bg-accent-10 disabled:text-neutral-alpha-4 disabled:bg-panel-3",
      },
      { iconOnly: true, size: 1, className: "size-6" },
      { iconOnly: true, size: 2, className: "size-8" },
      { iconOnly: true, size: 3, className: "size-10" },
      { iconOnly: true, size: 4, className: "size-12" },
      { iconOnly: true, size: 5, className: "size-14" },
    ],
    defaultVariants: {
      variant: "solid",
      theme: "primary",
      size: 2,
      width: "fit",
    },
  }
)

export const btnIconCva = cva([], {
  variants: {
    size: {
      1: "size-4",
      2: "size-[18px]",
      3: "size-5",
      4: "size-6",
      5: "size-[34px]",
    },
  },
  defaultVariants: {
    size: 2,
  },
})

const Button: React.FC<ButtonProps> = ({
  variant,
  size,
  theme,
  rounded,
  icon,
  width,
  loading,
  children,
  className,
  as,
  ...rest
}) => {
  const {
    startIconName,
    endIconName,
    type: iconType,
  } = icon || {
    startIcon: undefined,
    endIcon: undefined,
    type: undefined,
  }

  const Element = as || "button"

  return (
    <Element
      {...rest}
      className={cn(
        btnCva({
          variant,
          theme,
          size,
          rounded,
          width,
          iconOnly: !!icon && !children,
          loading,
          className,
        })
      )}
    >
      <IconRenderer
        className={btnIconCva({ size })}
        iconType={iconType}
        iconName={startIconName}
      />
      {children && <span className="block">{children}</span>}

      <IconRenderer
        className={btnIconCva({ size })}
        iconType={iconType}
        iconName={endIconName}
      />
    </Element>
  )
}

export default Button
