import React from "react"

const EmailEnvelope: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.161 4.964H17.84c.527 0 .982 0 1.356.03.395.033.789.105 1.167.297a3 3 0 011.311 1.311c.193.379.264.772.296 1.167.031.375.031.83.031 1.357v7.677c0 .527 0 .982-.03 1.356-.033.396-.104.789-.297 1.167a3 3 0 01-1.311 1.311c-.378.193-.772.264-1.167.297-.375.03-.83.03-1.357.03H6.162c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 01-1.311-1.31c-.193-.38-.264-.772-.296-1.168C2 17.785 2 17.33 2 16.803V9.126c0-.527 0-.982.03-1.357.033-.395.104-.788.297-1.167a3 3 0 011.311-1.31c.378-.193.772-.265 1.167-.297.375-.03.83-.03 1.356-.03zM5.046 6.983l6.32 5.171a1 1 0 001.267 0l6.321-5.171a20.038 20.038 0 00-1.154-.019H6.2c-.525 0-.88.001-1.154.019zm14.953 1.729l-6.1 4.99a3 3 0 01-3.799 0L4 8.712v8.052c0 .577 0 .95.024 1.233.022.271.06.372.085.421a1 1 0 00.437.437c.05.026.15.063.422.085.283.024.655.024 1.232.024h11.6c.577 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 00.437-.437c.025-.05.063-.15.085-.421.023-.284.024-.656.024-1.233v-7.6-.452z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default EmailEnvelope
