import React from "react"

import NewsletterForm from "../newsletter-form"

const NewsletterSubscription = () => {
  return (
    <div className="w-full">
      <div className="text-jar-3 font-medium text-neutral-12">
        Subscribe to our newsletter
      </div>
      <p className="text-jar-3 text-neutral-11 my-5">
        Get the freshest news and resources for developers, designers and
        digital creators in your inbox each week
      </p>
      <NewsletterForm small />
    </div>
  )
}

export default NewsletterSubscription
