import { POST } from "../http"

export function subscribeToNewsletter(email: string) {
  return POST(
    `${process.env.GATSBY_BASE_URL}/premium/api/v1/newsletter_subscribe`,
    {
      email,
    },
    {
      autoAttachToken: false,
    }
  )
}
