import React from "react"

const TvMonitor: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 6.964a3 3 0 013-3h14a3 3 0 013 3v8a3 3 0 01-3 3H5a3 3 0 01-3-3v-8zm3-1a1 1 0 00-1 1v8a1 1 0 001 1h14a1 1 0 001-1v-8a1 1 0 00-1-1H5zm7 15c-1.984 0-3.892.333-5.675.946a1 1 0 01-.65-1.891A19.41 19.41 0 0112 18.964c2.21 0 4.336.37 6.325 1.055a1 1 0 01-.65 1.891A17.408 17.408 0 0012 20.964z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default TvMonitor
