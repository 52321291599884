import React, { forwardRef, useState } from "react"
import clsx from "clsx"
import {
  FlagIcon,
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from "../icon"
import IconRenderer from "../icon/icon-renderer"
import { cva, type VariantProps } from "class-variance-authority"
import cn from "../../utils/classname"

const inputCva = cva(
  [
    "relative",
    "form-input",
    "flex",
    "items-center",
    "w-full",
    "overflow-ellipsis",
    "text-neutral-12",
  ],
  {
    variants: {
      variant: {
        outline: [
          "border",
          "bg-panel-1",
          "hover:bg-panel-3",
          "placeholder-neutral-alpha-11",
          "text-neutral-alpha-12",
          "focus:ring-0",
        ],
        soft: [
          "border-none",
          "rounded-md",
          "bg-neutral-alpha-3",
          "hover:bg-neutral-alpha-4",
          "focus:ring-0",
          "focus:bg-panel-1",
          "focus:outline-1",
          "focus:outline-offset-0",
          "focus:outline-neutral-alpha-6",
        ],
      },
      size: {
        1: [
          "text-jar-3",
          "font-normal",
          "placeholder:text-jar-3",
          "placeholder:font-normal",
          "h-8",
          "rounded-md",
          "px-2",
        ],
        2: [
          "text-jar-3",
          "font-normal",
          "placeholder:text-jar-3",
          "placeholder:font-normal",
          "h-10",
          "rounded-lg",
          "px-2.5",
        ],
        3: [
          "text-jar-3",
          "font-normal",
          "placeholder:text-jar-4",
          "placeholder:font-normal",
          "h-12",
          "rounded-[10px]",
          "px-3",
        ],
        4: [
          "text-jar-4",
          "font-normal",
          "placeholder:text-jar-4",
          "placeholder:font-normal",
          "h-14",
          "rounded-xl",
          "px-4",
        ],
      },
      hasError: {
        true: ["border-danger-11"],
        false: [],
      },
      iconPosition: {
        start: [],
        end: [],
        both: [],
      },
    },
    compoundVariants: [
      {
        variant: "outline",
        hasError: false,
        className: [
          "border-neutral-alpha-6",
          "focus:border-neutral-alpha-12",
          "focus:shadow-[0px_0px_0px_2px_rgba(2,2,52,0.08)]",
        ],
      },
      {
        variant: "outline",
        hasError: true,
        className: [
          "border-danger-alpha-9",
          "focus:border-danger-alpha-9",
          "shadow-[0px_0px_0px_2px_#ff00001a]",
          "focus:shadow-[0px_0px_0px_2px_#ff00001a]",
        ],
      },
      {
        variant: "soft",
        hasError: true,
        className: [
          "bg-danger-alpha-3",
          "focus:bg-danger-alpha-3 focus:outline-danger-alpha-3",
        ],
      },
      { iconPosition: "start", size: 1, className: "pl-8" },
      { iconPosition: "start", size: 2, className: "pl-[38px]" },
      { iconPosition: "start", size: 3, className: "pl-11" },
      { iconPosition: "start", size: 4, className: "pl-12" },
      { iconPosition: "end", size: 1, className: "pr-8" },
      { iconPosition: "end", size: 2, className: "pr-[38px]" },
      { iconPosition: "end", size: 3, className: "pr-11" },
      { iconPosition: "end", size: 4, className: "pr-12" },
      { iconPosition: "both", size: 1, className: "px-8" },
      { iconPosition: "both", size: 2, className: "px-[38px]" },
      { iconPosition: "both", size: 3, className: "px-11" },
      { iconPosition: "both", size: 4, className: "px-12" },
    ],
    defaultVariants: {
      variant: "outline",
      size: 2,
      hasError: false,
    },
  }
)

const iconCva = cva(
  [
    "absolute",
    "top-1/2",
    "transform",
    "-translate-y-1/2",
    "text-neutral-alpha-9",
    "group-hover:text-neutral-alpha-11",
    "z-[2]",
  ],

  {
    variants: {
      position: {
        start: [],
        end: [],
      },
      size: {
        1: ["size-5"],
        2: ["size-6"],
        3: ["size-6"],
        4: ["size-6"],
      },
      focused: {
        true: ["text-neutral-alpha-11"],
        false: [],
      },
    },
    compoundVariants: [
      { position: "start", size: 1, className: "left-2" },
      { position: "start", size: 2, className: "left-2.5" },
      { position: "start", size: 3, className: "left-3" },
      { position: "start", size: 4, className: "left-4" },
      { position: "end", size: 1, className: "right-2" },
      { position: "end", size: 2, className: "right-2.5" },
      { position: "end", size: 3, className: "right-3" },
      { position: "end", size: 4, className: "right-4" },
    ],
    defaultVariants: {
      size: 2,
    },
  }
)

interface IconProps {
  type: IconType
  name?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}

export type EndIconProps = React.HTMLAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLButtonElement>

type InputCva = VariantProps<typeof inputCva>

export interface InputFieldProps
  extends React.DetailedHTMLProps<
      Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
      HTMLInputElement
    >,
    React.AriaAttributes,
    InputCva {
  label?: string
  helperText?: string
  optional?: boolean
  startIcon?: IconProps
  endIcon?: IconProps
  endIconAs?: React.ElementType
  endIconProps?: EndIconProps
}

const InputField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputFieldProps
> = (props, ref) => {
  const {
    label,
    className,
    hasError,
    helperText,
    id,
    optional,
    size,
    variant,
    startIcon,
    endIcon,
    endIconProps,
    endIconAs,
    onFocus,
    onBlur,
    ...rest
  } = props

  const [focused, setFocused] = useState(false)

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true)
    onFocus && onFocus(event)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false)
    onBlur && onBlur(event)
  }

  const EndIconElement = endIconAs || "div"

  return (
    <div className={cn("flex flex-col gap-2", className)}>
      {label && (
        <label
          htmlFor={id}
          className="text-jar-3 font-semibold text-neutral-12"
        >
          {label}
          {optional && (
            <span className="text-neutral-11 ml-0.5 font-medium">{`(Optional)`}</span>
          )}
        </label>
      )}
      <div className="group relative">
        {startIcon && (
          <div className={cn(iconCva({ position: "start", focused }))}>
            <IconRenderer
              className={iconCva({ size })}
              iconName={startIcon.name}
              iconType={startIcon.type}
            />
          </div>
        )}
        <input
          ref={ref}
          className={cn(
            inputCva({
              variant,
              size,
              hasError,
              iconPosition: startIcon
                ? endIcon
                  ? "both"
                  : "start"
                : endIcon
                  ? "end"
                  : undefined,
            })
          )}
          id={id}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        {endIcon && (
          <EndIconElement
            className={cn(iconCva({ position: "end", focused }))}
            {...endIconProps}
          >
            <IconRenderer
              className={iconCva({ size })}
              iconName={endIcon.name}
              iconType={endIcon.type}
            />
          </EndIconElement>
        )}
      </div>

      {helperText && (
        <div className="flex items-center">
          <span>
            {hasError && (
              <Icon.Solid
                name="CircleExclamation"
                className="text-danger-11 block size-4"
              />
            )}
          </span>

          <span
            className={clsx(
              "block text-jar-2 font-normal pl-1",
              hasError ? "text-danger-11" : "text-neutral-11"
            )}
          >
            {helperText}
          </span>
        </div>
      )}
    </div>
  )
}

export default forwardRef(InputField)
