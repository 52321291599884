import React from "react"

const Layers2: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51975 2.76259C9.98092 2.53763 10.52 2.53763 10.9812 2.76259L17.6221 6.00206C18.87 6.61082 18.87 8.3892 17.6221 8.99796L15.5679 10L17.6221 11.0021C18.87 11.6108 18.87 13.3892 17.6221 13.998L10.9812 17.2374C10.52 17.4624 9.98091 17.4624 9.51975 17.2374L2.87883 13.998C1.63088 13.3892 1.63088 11.6108 2.87883 11.0021L4.93304 10L2.87883 8.99796C1.63088 8.3892 1.63089 6.61082 2.87883 6.00206L9.51975 2.76259ZM6.83379 10.9272L3.60954 12.5L10.2505 15.7395L16.8914 12.5L13.6671 10.9272L10.9812 12.2374C10.52 12.4624 9.98092 12.4624 9.51975 12.2374L6.83379 10.9272ZM10.2505 4.26054L3.60954 7.50001L10.2505 10.7395L16.8914 7.50001L10.2505 4.26054Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Layers2
