import React from "react"

const MedalTrophy: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.964a6 6 0 100 12 6 6 0 000-12zm5 12.246a8 8 0 10-10 0V22a1.75 1.75 0 002.533 1.566L12 22.332l2.467 1.234A1.75 1.75 0 0017 22V16.21zm-4.793 1.752a7.967 7.967 0 002.793-.58v4.214l-2.217-1.109a1.75 1.75 0 00-1.566 0L9 21.597v-4.214a7.967 7.967 0 003.207.579z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default MedalTrophy
