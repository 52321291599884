import React, { useEffect, useState } from "react"
import { type SubmitHandler, useForm } from "react-hook-form"
import z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

import { subscribeToNewsletter } from "../services/newsletter/api"

import InputField from "../ui/input-field"
import Button from "../ui/button"

type Inputs = {
  email: string
}

const schema = z.object({
  email: z
    .string()
    .nonempty({ message: "Email is required" })
    .email("Invalid email address"),
})

export default function NewsletterForm({ small }: { small: boolean }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
  const [error, setError] = useState("")

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isSubmitting) return
    setIsSubmitting(true)
    setError("")

    try {
      await subscribeToNewsletter(data.email)
      setIsSubmitSuccessful(true)
    } catch (error) {
      setError(
        error?.message || "Something went wrong. Please try again later."
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ email: "" })
      setError("")
    }
  }, [isSubmitSuccessful, reset])

  if (isSubmitSuccessful) {
    return (
      <span className="block font-medium text-jar-3 text-success-11">
        Success! You&apos;ve been added to our newsletter.
      </span>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col sm:flex-row">
        <InputField
          placeholder="Email"
          size={small ? 1 : 2}
          variant="soft"
          className="flex-auto mb-3 sm:mb-0 sm:mr-3"
          {...register("email")}
          helperText={errors.email && errors.email.message?.toString()}
          hasError={!!errors.email}
        />
        <Button
          type="submit"
          size={small ? 2 : 3}
          variant="solid"
          theme="neutral"
          className="self-start w-full sm:w-auto"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Subscribe
        </Button>
      </div>
      {error && (
        <span className="mt-3 block font-medium text-jar-3 text-danger-11">
          {error}
        </span>
      )}
    </form>
  )
}
