import React from "react"

const SpaceStar: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 4.964a8 8 0 00-6.615 12.5 9.965 9.965 0 016.615-2.5c2.536 0 4.853.945 6.615 2.5A8 8 0 0012 4.965zm5.292 14a7.966 7.966 0 00-5.292-2c-2.029 0-3.88.755-5.292 2a7.967 7.967 0 005.292 2c2.029 0 3.88-.754 5.292-2zM2 12.964c0-5.522 4.477-10 10-10s10 4.478 10 10c0 2.56-.964 4.899-2.547 6.667A9.978 9.978 0 0112 22.964a9.978 9.978 0 01-7.453-3.333A9.967 9.967 0 012 12.964z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M9.288 9.499l1.342-.671a.52.52 0 00.233-.233l.671-1.343a.52.52 0 01.931 0l.672 1.343c.05.1.132.182.233.232l1.342.672a.52.52 0 010 .93l-1.342.672a.52.52 0 00-.233.233l-.671 1.343a.52.52 0 01-.931 0l-.672-1.343a.52.52 0 00-.233-.233l-1.342-.671a.52.52 0 010-.931z"
      ></path>
    </svg>
  )
}

export default SpaceStar
